import react, { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes, useParams, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { useAuth0 } from "@auth0/auth0-react";
import moment from "moment";

import Main from "Pages/Main/Main";
import Alert from "Pages/Alert/Alert";
import Live from "Pages/Live/Live";

import CameraList from "Pages/Cameras/CameraList";

import { NotifyToast } from 'Shared/Components/NotifyToast'
import { webUrl } from "Shared/utils";

const Portal = () => {
  const { error, isAuthenticated, isLoading, user, getAccessTokenSilently, loginWithRedirect, logout } = useAuth0();
  const { t, i18n } = useTranslation()

  const [start, setStart] = useState(false)

  useEffect(() => {
    const getToken = async () => {
      try {
        const res = await getAccessTokenSilently({
          ignoreCache: true,
          scope: "openid offline_access",
          grant: "refresh_token",
          detailedResponse: true,
        })

        sessionStorage.setItem('accessToken', res.access_token)

        if (!start) {
          setStart(true)
        }

        setTimeout(async function()  {
          await getToken()
        }, (res.expires_in - 100)*1000);
      }
      catch (e){
        logout({ logoutParams: { returnTo: webUrl } })
      }
    }

    if (!isLoading) {
      if (!error) {
        if (isAuthenticated) {
          getToken()
        }
        else {
          loginWithRedirect({ appState: { returnTo: window.location.pathname } })
        }
      }
      else {
        const errorMsg = new String(error).toString()

        switch (errorMsg) {
          case "Error: Please verify your email before logging in.":
            alert(t("loginError_unverified"))
            break
          case "Error: client requires organization membership, but user does not belong to any organization":
            alert(t("loginError_noOrg"))
            break
          default:
            alert(errorMsg)
            break
        }

        logout({ logoutParams: { returnTo: webUrl } })
      }
    }
  }, [isLoading, isAuthenticated])

  return (<>
    {start &&
      <Routes>
        <Route path='/portal/:organization?/:project?' element={<Main />}>
          <Route index element={<Navigate to="./alert" replace />} />

          <Route exact path="alert" element={<Alert />} />
          <Route exact path="live" element={<Live />} />

          <Route exact path="cameras/list" element={<CameraList />} />
        </Route>

        <Route index element={<Navigate to="./portal" replace />} />
      </Routes>
    }

    <div>{<NotifyToast/>}</div>
  </>)
}

function App() {
  return (<Portal />)
}

export default App;
