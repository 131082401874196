import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { Paper } from '@mui/material'
import { Container, Col, Row } from 'react-bootstrap'
import { RiPagesLine } from "react-icons/ri";
import { PiMapPinAreaFill } from "react-icons/pi";

import RoiDrawModal from './components/RoiDrawModal';

import useFetchData from 'Shared/Hooks/useFetchData';
import DataTable from 'Shared/Components/DataTable';
import LoadingScreen from 'Shared/Components/LoadingScreen';
import { apiUrl } from 'Shared/utils';
import { showError } from 'Shared/Components/NotifyToast';
import useAuth from 'Shared/Hooks/useAuth';

const CameraList = () => {
  const { t, i18n } = useTranslation()
  const { organization, project } = useParams()
  const { send } = useFetchData()
  const { role } = useAuth()

  const [cameras, setCameras] = useState([])
  const [loading, setLoading] = useState(true);
  const [roiModalOpen, setRoiModalOpen] = useState();

  useEffect(() => {
    const init = async () => {
      const body = await send({
        method: "GET",
        url: `${apiUrl}/camera/list`,
        returnType: "json"
      })

      setCameras(body.map((i) => ({
        "name": i["name"],
        "camera_id": i["camera_id"],
        "ai_enabled": i["ai_enabled"],
        "url": i["url"],
        "online": (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
            <span style={{ height: "15px", width: "15px", backgroundColor: i["online"] ? "green" : "red", borderRadius: "50%" }}></span>
          </div>
        )
      })))

      setLoading(false)
    }

    init()
  }, [])

  return (<>
    <Container fluid className="mainContainer">
      <Row style={{ height: "100%" }}>
        <Col xs={12} style={{ height: "100%", overflow: "auto", borderRadius: "15px", paddingLeft: 0, paddingRight: 0}}>
          <Paper elevation={0} sx={{ height: "100%", display: "flex", flexFlow: "column" }}>
            <DataTable
              data={cameras} count={null}
              columns={{
                "online": { "label": t("cameras_online"), "sorting": false, "nowrap": true },
                "name": { "label": t("cameras_cameraName"), "sorting": false, "nowrap": true },
              }}
              renderActions={(row) => { return [
                (["pm", "admin"].includes(role)) && {icon: <RiPagesLine size={20}/>, text: t("cameras_cameraPage"), onClick: function(row) { window.open(row["url"]) }},
                (["admin"].includes(role) && row["ai_enabled"] === true) && {icon: <PiMapPinAreaFill size={20}/>, text: t("cameras_drawRoi"), onClick: function(row) { row["ai_enabled"] ? setRoiModalOpen(row["camera_id"]) : showError("AI Detection Not Enabled") }},
              ]}}
            />
          </Paper>
        </Col>
      </Row>
    </Container> 

    <LoadingScreen isOpen={loading} />

    {roiModalOpen && 
      <RoiDrawModal camera_id={roiModalOpen} toggle={() => setRoiModalOpen()}/>
    }
  </>)
}

export default CameraList
