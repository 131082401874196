import React from 'react'
import { useParams } from 'react-router-dom'

import { jwtDecode } from 'Shared/utils'

const useAuth = () => {
  const { organization, project } = useParams()

  const profile = jwtDecode()

  const role = (!organization || !project) ? null : profile["permissions"].includes(profile["org_name"]) ? "admin" : profile['permissions'].filter((i) => i.split(':')[1] == project)[0].split(':')[2]

  return { profile, role };
}

export default useAuth
